import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from './shared/services/user.service';
import { ProductService } from './shared/services/product.service';
import { HttpClient } from '@angular/common/http';
import { Url } from './http/url-constants';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AppUtilService {

  constructor(private cookieService: CookieService, private userService: UserService, private productService: ProductService,
              private http: HttpClient, public title: Title) {
  }

  load(url: string) {
    this.http.get(url).subscribe((result: any) => {
      if (result && result.ServerUrl) {
        Url.serverUrl = result.ServerUrl;
      }
      this.productService.setCategories();
      if (this.cookieService.get('user')) {
        this.userService.setUserData(JSON.parse(this.cookieService.get('user')));
      } else {
        this.userService.Observable.next(false);
      }
      if (localStorage.cartItems) {
        this.productService.Observable.next(JSON.parse(localStorage.cartItems));
      }
      if (this.cookieService.get('services')) {
        this.productService.setServices(JSON.parse(this.cookieService.get('services')));
      }
    });
  }

  public findObject(list: any[], attr: string, matchedValue: any): any {
    return list.find(result => result[attr] === (matchedValue?.toString() || matchedValue));
  }

  public setBrowserTitle(title: string): void {
    this.title.setTitle(title);
  }
}
