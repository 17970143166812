<div *ngIf="!sidebar; else showSidebarContent" class="row">
    <div *ngFor="let item of sectionItems" class="col-md-4 service-block">
        <div class="media">
          <img appLazyLoad alt="No Image" class="media-image" src="{{item.displayImg}}">
            <div class="media-body">
                <h4>{{item.displayText}}</h4>
                <p>{{item.displaySubText}}</p>
            </div>
        </div>
    </div>
</div>
<ng-template #showSidebarContent>
    <!-- Start Services -->
    <div class="product-service">
        <div *ngFor="let item of sectionItems; let index = index;"
             [ngClass]="{'border-0 m-0' : index === (sectionItems.length - 1)}"
             class="media">
          <img appLazyLoad alt="No Image" class="media-image" src="{{item.displayImg}}" width="40px">
            <div class="media-body">
                <h4>{{item.displayText}}</h4>
                <p>{{item.displaySubText}}</p>
            </div>
        </div>
    </div>
    <!-- End Services -->
</ng-template>
