<!-- Start Categories List -->
<div [class.open]="collapse" class="collection-collapse-block border-0">
    <h3 (click)="collapse = !collapse" class="collapse-block-title">{{title}}</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let category of categories">
                    <a
                            (click)="categorySelected.emit() ;productService.searchObservable.next(null)"
                            [routerLink]="['/gifts/' + (category?.category?.name ? category?.category?.name : category?.name) + (category?.category?.name ?  ('/' + category?.name) : '')]">
                        {{category.displayName}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Categories List -->
