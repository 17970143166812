import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { NewProductSlider } from '../../data/slider';
import { WebService } from '../../../http/web-service.service';
import { Url } from '../../../http/url-constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default
  @Input() type: string = 'fashion'; // Default Fashion

  public products: any[] = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public webService: WebService, public titleService: Title) {
  }

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.webService.get(Url.API.shop.getSidebarProducts).subscribe((response) => {
      if (response.status.code === 200) {
        this.products = response.products;

      }
    });
  }
}
