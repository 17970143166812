import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { QuickViewComponent } from '../modal/quick-view/quick-view.component';
import { CartModalComponent } from '../modal/cart-modal/cart-modal.component';
import { WebService } from '../../../http/web-service.service';
import { UserService } from '../../services/user.service';
import { AddToCartProto } from '../components.constants';
import { Url } from '../../../http/url-constants';
import { ToasterService } from '../../services/toaster.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilService } from '../../../app-util.service';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss'],
})
export class ProductBoxOneComponent implements OnInit, OnDestroy {

  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @ViewChild('quickView') QuickView: QuickViewComponent;
  @ViewChild('cartModal') CartModal: CartModalComponent;
  public ImageSrc: string;
  public addToCartRequest = new AddToCartProto();
  public addToWishlistRequest = new AddToCartProto();
  public userLoggedIn: boolean = false;
  public userSubscription: Subscription;
  @Input() fromOwnBox: boolean = false;

  constructor(private productService: ProductService, private webService: WebService, private userService: UserService,
              private toaster: ToasterService, public router: Router, public appUtilService: AppUtilService) {
  }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    }
    this.userSubscription = this.userService.getObserver().subscribe((result) => {
      this.userLoggedIn = !!result;
    });
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    /*for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color);
      }
    }*/
    return uniqColor;
  }

  // Change Variants
  ChangeVariants(color, product) {
    if (product && product.variants) {
      product?.variants.map((item) => {
        if (item.color === color) {
          product.images.map((img) => {
            if (img.image_id === item.image_id) {
              this.ImageSrc = img.src;
            }
          });
        }
      });
    }
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    if (this.userLoggedIn) {
      this.addToCartRequest.productIds = [product.id];
      this.addToCartRequest.userId = this.userService.getUserProperty('userId');
      this.webService.post(Url.API.cart.add, this.addToCartRequest)
        .subscribe((response) => {
          if (response.status.code === 200) {
            this.productService.cartItemsAdded.next(true);
            this.toaster.show('Successfully Added to Cart');
          } else {
            this.toaster.show(response.status.message, true);
          }
        });
    } else {
      this.productService.addToCart(product);
      this.toaster.show('Successfully Added to Cart');
    }
  }

  addToWishlist(product: any) {
    if (this.userLoggedIn) {
      this.addToWishlistRequest.productIds = [product.id];
      this.addToWishlistRequest.userId = this.userService.getUserProperty('userId');
      this.webService.post(Url.API.wishlist.add, this.addToWishlistRequest)
        .subscribe((response) => {
          if (response.status.code === 200) {
            this.toaster.show('Product has been added to Wishlist!');
          } else {
            this.toaster.show(response.status.message, true);
          }
        });
    } else {
      this.productService.addToWishlist(product);
      this.toaster.show('Product has been added to Wishlist');
    }
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  onChangeUrl(product) {
    return product.metaPath;
  }
}
