<header [class.fixed]="stick && sticky" [ngClass]="class" class="sticky">
    <div class="mobile-fix-option"></div>
    <div *ngIf="topbar" class="top-header">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li>Welcome to Our store Giftoholics</li>
                            <li><i aria-hidden="true" class="fa fa-phone"></i>Call Us:
                                <a class="text-theme-primary" href="tel: +91 89052 53279">+91 89052 53279</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-right">
                    <ul class="header-dropdown">
                        <li>

                        </li>
                        <li class="mobile-wishlist">
                            <a [routerLink]="['/gifts/wishlist']"><i aria-hidden="true" class="fa fa-heart"></i>Wishlist</a>
                        </li>
                        <li class="onhover-dropdown mobile-account">
                            <i aria-hidden="true" class="fa fa-user"></i> {{userLoggedIn ? 'My Account' : 'Welcome'}}
                            <ul class="onhover-show-div">
                                <li *ngIf="!userLoggedIn" [routerLink]="['/login']">
                                    <a data-lng="en">
                                        Login
                                    </a>
                                </li>
                                <li *ngIf="!userLoggedIn" [routerLink]="['/register']">
                                    <a data-lng="en">
                                        Register
                                    </a>
                                </li>
                                <li *ngIf="userLoggedIn" [routerLink]="['/gifts/orders']">
                                    <a data-lng="es">
                                        My Orders
                                    </a>
                                </li>
                                <li *ngIf="userLoggedIn" [routerLink]="['/profile']">
                                    <a data-lng="es">
                                        Edit Profile
                                    </a>
                                </li>
                                <li (click)="logout()" *ngIf="userLoggedIn">
                                    <a data-lng="es">
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
                        <app-left-menu></app-left-menu>
                        <div class="brand-logo" routerLink="/home">
                            <a id="headerlogo">
                              <img appLazyLoad [src]="'assets/images/icon/logo.png'" alt="logo" class="img-fluid" width="75px">
                            </a>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <div>
                            <app-menu></app-menu>
                        </div>
                        <div>
                            <app-settings></app-settings>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
