<!-- side-bar single product slider start -->
<div class="theme-card">
    <h5 class="title-border">{{ title }}</h5>
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let product of products | slice:0:3 ">
                        <ng-template carouselSlide>
                <div>
                    <div *ngFor="let product of products | slice:0:3 " class="media">
                        <a [routerLink]="['/gifts/products/' + product.metaPath]"
                       >
                          <img appLazyLoad [src]="product.displayImage"
                                 [alt]="product?.tags[0]" class="img-fluid w-auto">
                        </a>
                        <div [routerLink]="['/gifts/products/' + product.metaPath]"
                             class="media-body align-self-center">
                            <bar-rating [rate]="product.ratings" [hidden]="product.ratings == null" [readOnly]="true"></bar-rating>
                            <a>
                                <h5 class="mt-2">{{ product.productName | titlecase }}</h5></a>
                            <h4>
                                {{ product?.totalPrice | currency: 'INR' : 'symbol' : '1.2'}}
                                <del *ngIf="product?.discount">
                          <span class="money">
                            {{ product?.totalPrice | currency: 'INR' :'symbol' : '1.2'}}
                          </span>
                                </del>
                            </h4>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                    <div *ngFor="let product of products | slice:3:6 " class="media">
                    <a [routerLink]="['/gifts/products/' + product.metaPath]"
                      >
                      <img appLazyLoad [src]="product.displayImage"
                                 [alt]="product?.tags[0]"
                                 alt="" class="img-fluid w-auto">
                        </a>
                        <div [routerLink]="['/gifts/products/' + product.metaPath]"
                             class="media-body align-self-center">
                            <bar-rating [rate]="product.ratings" [hidden]="product.ratings == null" [readOnly]="true"></bar-rating>
                            <a>
                                <h5 class="mt-2">{{ product.productName | titlecase }}</h5></a>
                            <h4>
                                {{ product?.totalPrice | currency: 'INR' : 'symbol' : '1.2'}}
                                <del *ngIf="product?.discount">
                          <span class="money">
                            {{ product?.totalPrice | currency: 'INR' :'symbol' : '1.2'}}
                          </span>
                                </del>
                            </h4>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
