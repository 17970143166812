import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastrService: ToastrService) {
  }

  show(message: string, error?: boolean) {
    if (error) {
      this.toastrService.error(message, '', {
        timeOut: 5000,
        positionClass: 'toast-bottom-center'
      });
    } else {
      this.toastrService.success(message, '', {
        timeOut: 3000,
        positionClass: 'toast-bottom-center'
      });
    }
  }

  showSimpleToaster(message: string) {
    this.toastrService.show(message, '', {
      timeOut: 3000,
      positionClass: 'toast-bottom-center'
    });
  }
}
