import { Component } from '@angular/core';
import {
    NavigationEnd,
    Router
} from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    title = 'giftoholics';

    constructor(private router: Router, private viewportScroller: ViewportScroller) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }
}
