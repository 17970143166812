import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppUtilService } from '../../../app-util.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public collapse: boolean = true;
  @Input('categories') categories: any[] = [];
  @Input('title') title: string;
  @Input('sub-category') subCategory: boolean;
  @Output() categorySelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(public productService: ProductService) {
  }

  ngOnInit(): void {

  }


  /*onSetTitle(cat: any) {
    let selectedSubCategory;
    if (this.subCategory) {
      if (cat.id && this.appUtilService.findObject(this.categories, 'id', cat.id)) {
        selectedSubCategory = this.appUtilService.findObject(this.categories, 'id', cat.id);
      }
      this.titleService.setTitle(selectedSubCategory?.category?.displayName + ' | ' + selectedSubCategory?.displayName);
    } else {
      this.titleService.setTitle(cat?.displayName);
    }
    //  this.titleService.setTitle(title);
  }*/
}
