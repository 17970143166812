<!--modal popup start-->
<ng-template #newsletter class="theme-modal" let-modal>
  <div class="modal-content">
    <div class="modal-body modal7">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="modal-bg">
              <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="offer-content">
                <h2>newsletter</h2>
                <form #newsletterForm="ngForm" (ngSubmit)="newsletterForm.valid && subscribe()"
                      class="auth-form needs-validation">
                  <div class="form-group mx-sm-3">
                    <input #email="ngModel" [(ngModel)]="emailId" autocomplete="off" class="form-control"
                           id="mce-EMAIL" name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" placeholder="Enter your email"
                           required="required" type="email">
                    <div *ngIf="email.errors && (email.touched || email.dirty || newsletterForm.submitted)">
                      <span [hidden]="!email.errors.required" class="text-danger">
                        This field is required.
                      </span>
                      <span [hidden]="!email.errors.pattern" class="text-danger">
                        Please enter a valid Email Address.
                      </span>
                    </div>
                    <button class="btn btn-solid" id="mc-submit" type="submit">subscribe</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--modal popup end-->
