import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { WebService } from '../../../../http/web-service.service';
import { ToasterService } from '../../../services/toaster.service';
import { Url } from '../../../../http/url-constants';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('newsletter', {static: false}) NewsLetterModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  @ViewChild('newsletterForm') newsletterForm: NgForm;
  public emailId: string;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private modalService: NgbModal,
              private webService: WebService, private toasterService: ToasterService) {
  }

  private static getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (localStorage.getItem('newsletter') !== 'true') {
        this.openModal();
      }
      localStorage.setItem('newsletter', 'true');
    }, 3500);
  }

  openModal() {
      if (isPlatformBrowser(this.platformId)) {
        this.modalService.open(this.NewsLetterModal, {
          size: 'lg',
          ariaLabelledBy: 'NewsLetter-Modal',
          centered: true,
          windowClass: 'theme-modal newsletterm NewsLetterModal'
        }).result.then((result) => {
          this.modalOpen = true;
          `Result ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${NewsletterComponent.getDismissReason(reason)}`;
        });
      }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  subscribe() {
    this.webService.post(Url.API.newsletter.subscribe, {email: this.emailId})
      .subscribe((response) => {
        if (response.status.code === 200) {
          this.toasterService.show('Successfully Subscribed');
          this.modalService.dismissAll();
        } else {
          this.toasterService.show(response.status.message, true);
        }
      });
  }

}
