import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../services/product.service';
import { WebService } from '../../../http/web-service.service';
import { UserService } from '../../services/user.service';
import { Url } from '../../../http/url-constants';
import { AppUtilService } from '../../../app-util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  public products: any[] = [];
  public search = false;
  public cartItemsSubscription: Subscription;
  public userSubscription: Subscription;
  public subTotal = 0;
  public userLoggedIn: boolean;
  public userId: string;
  public searchProductText: string;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private translate: TranslateService,
              public productService: ProductService, private webService: WebService,
              public router: Router,
              private userService: UserService, public appUtilService: AppUtilService) {
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.getObserver().subscribe((result) => {
      this.userLoggedIn = !!result;
      if (this.cartItemsSubscription) {
        this.cartItemsSubscription.unsubscribe();
      }

      if (this.userLoggedIn) {
        this.cartItemsSubscription = this.productService.cartItemsAdded.subscribe((products) => {
          if (products) {
            this.getProducts();
          } else {
            this.products = [];
          }
        });
      } else {
        this.cartItemsSubscription = this.productService.getProducts().subscribe((products) => {
          if (products) {
            this.subTotal = 0;
            this.products = products;
            this.products.forEach((v) => {
              this.subTotal += (v.sellingPrice + v.shippingPrice);
            });
          }
        });
      }
    });
  }

  searchToggle() {
    this.search = !this.search;
    this.searchProductText = null;

  }

  onSearchProducts() {
    this.productService.searchObservable.next(this.searchProductText);
    this.router.navigate(['/gifts']);
    this.search = false;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  removeItem(product: any) {
    if (this.userLoggedIn) {
      this.webService.delete(Url.API.cart.delete + product.cartItemId).subscribe((response) => {
        if (response.status.code === 200) {
          this.productService.cartItemsAdded.next(true);
          this.getProducts();
        }
      });
    } else {
      this.productService.removeCartItem(product);
    }
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  getProducts() {
    this.products = [];
    this.subTotal = 0;
    if (this.userLoggedIn && this.userService.getUserProperty('userId')) {
      this.userId = this.userService.getUserProperty('userId');
      this.webService.get(Url.API.cart.get + this.userId).subscribe((response) => {
        if (response) {
          this.products = response.items;
          this.products.forEach((v) => {
            for (const i in v.product) {
              v[i] = v.product[i];
            }
          });
          this.products.forEach((v) => {
            this.subTotal += (v.sellingPrice + v.shippingPrice);
          });
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.cartItemsSubscription) {
      this.cartItemsSubscription.unsubscribe();
    }
  }

}
