import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductService } from '../../services/product.service';

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnDestroy {

    public menuItems: any[];
    public menuItemsSubscription: Subscription;

    constructor(private router: Router, public navServices: NavService, private productService: ProductService) {
        this.menuItemsSubscription = this.productService.getCategories().subscribe(menuItems => this.menuItems = menuItems);
        this.router.events.subscribe((event) => {
            this.navServices.mainMenuToggle = false;
        });
    }

    ngOnInit(): void {
    }

    leftMenuToggle(): void {
        this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
    }

    // Click Toggle menu (Mobile)
    toggleNavActive(item) {
        item.active = !item.active;
        if (item.subCategories.length === 0) {
          this.router.navigate(['/gifts/' + item.name])
        }
    }

    onHover(menuItem) {
        if (window.innerWidth > 1200 && menuItem) {
            document.getElementById('unset').classList.add('sidebar-unset');
        } else {
            document.getElementById('unset').classList.remove('sidebar-unset');
        }
    }

    ngOnDestroy() {
        if (this.menuItemsSubscription) {
            this.menuItemsSubscription.unsubscribe();
        }
    }

}
