<div class="main-navbar">
  <div id="mainnav">
    <ul [class.opennav]="navServices?.mainMenuToggle" class="nav-menu">

      <li class="back-btn">
        <div (click)="mainMenuToggle()" class="mobile-back text-right">
          <span>Back</span>
          <i aria-hidden="true" class="fa fa-angle-right pl-2"></i>
        </div>
      </li>

      <li *ngFor="let menuItem of menuItems | slice: 0 : 5" [class.mega-menu]="menuItem.megaMenu">
        <!-- Link -->
        <a  [routerLinkActiveOptions]="{exact: true}"

           [routerLink]="['/gifts/' + '/' + menuItem.name]"
           class="nav-link" routerLinkActive="active">
          {{ menuItem.displayName }}
          <div *ngIf="menuItem.badge" class="lable-nav">{{ menuItem.badgeText | translate }}</div>
          <span *ngIf="menuItem.subCategories && menuItem.subCategories.length > 0" class="sub-arrow"></span>
        </a>

        <!-- 2nd Level Menu -->
        <ul *ngIf="menuItem.subCategories && menuItem.subCategories.length > 0" [class.opensubmenu]="menuItem.active"
            class="nav-submenu">
          <li *ngFor="let childrenItem of menuItem.subCategories">
            <!-- Sub -->
            <a [routerLinkActiveOptions]="{exact: true}"
               [routerLink]="['/gifts' + '/' + menuItem.name + '/' + childrenItem.name]"

               routerLinkActive="active">
              {{ childrenItem.displayName | translate }}
              <span *ngIf="childrenItem.badge" class="new-tag">{{ childrenItem.badgeText | translate }}</span>
              <span *ngIf="childrenItem.children" class="sub-arrow"></span>
            </a>

            <!-- 3rd Level Menu -->
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <!-- Link -->
                <a *ngIf="childrenSubItem.type === 'link' " [routerLinkActiveOptions]="{exact: true}"
                   [routerLink]="childrenSubItem.path"

                   routerLinkActive="active">
                  {{ childrenSubItem.title | translate }}
                  <span *ngIf="childrenSubItem.badge" class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Link -->
                <a *ngIf="childrenSubItem.type === 'extLink' "
                   href="{{ childrenSubItem.path }}">
                  {{ childrenSubItem.title | translate }}
                  <span *ngIf="childrenSubItem.badge" class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a *ngIf="childrenSubItem.type === 'extTabLink' " href="{{ childrenSubItem.path }}"
                   target="_blank">
                  {{ childrenSubItem.title | translate }}
                  <span *ngIf="childrenSubItem.badge" class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div *ngIf="menuItem.megaMenu && menuItem.children" class="mega-menu-container">
          <div class="container">
            <div class="row">
              <div *ngFor="let childrenItem of menuItem.children" class="col mega-box">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                      {{ childrenItem.title | translate }}
                      <span *ngIf="childrenItem.children" class="sub-arrow"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                        <!-- Sub -->
                        <a *ngIf="childrenSubItem.type === 'sub'" href="javascript:void(0)">
                          {{ childrenSubItem.title | translate }}
                          <span *ngIf="childrenSubItem.badge"
                                class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                        </a>
                        <!-- Link -->
                        <a *ngIf="childrenSubItem.type === 'link' " [routerLinkActiveOptions]="{exact: true}"
                           [routerLink]="childrenSubItem.path"
                           routerLinkActive="active">
                          {{ childrenSubItem.title | translate }}
                          <span *ngIf="childrenSubItem.badge"
                                class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                        </a>
                        <!-- External Link -->
                        <a *ngIf="childrenSubItem.type === 'extLink' "
                           href="{{ childrenSubItem.path }}">
                          {{ childrenSubItem.title | translate }}
                          <span *ngIf="childrenSubItem.badge"
                                class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                        </a>
                        <!-- External Tab Link -->
                        <a *ngIf="childrenSubItem.type === 'extTabLink' " href="{{ childrenSubItem.path }}"
                           target="_blank">
                          {{ childrenSubItem.title | translate }}
                          <span *ngIf="childrenSubItem.badge"
                                class="new-tag">{{ childrenSubItem.badgeText | translate }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
