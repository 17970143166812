import {
    Component,
    OnInit
} from '@angular/core';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'app-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

    public url: string;

    constructor(private toasterService: ToasterService) {
        this.url = window.location.href;
    }

    ngOnInit(): void {
    }

    showToaster() {
        this.toasterService.showSimpleToaster('Copied to Clipboard');
    }

}
