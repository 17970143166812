import {
  Component,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebService } from '../../../../http/web-service.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { Url } from '../../../../http/url-constants';
import { AppUtilService } from '../../../../app-util.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  public blogId: string;
  public blogDetails: any;

  constructor(private route: ActivatedRoute, private webService: WebService, private toaster: ToasterService,
              public appUtilService: AppUtilService) {
    route.queryParams.subscribe(params => {
      this.blogId = params.id;
      this.getBlogDetails();
    });
  }

  ngOnInit(): void {
    // this.appUtilService.setBrowserTitle('Blog');
  }

  getBlogDetails() {
    this.webService.get(Url.API.blogs.getById + this.blogId).subscribe((response) => {
      this.blogDetails = response.blog;
      this.appUtilService.setBrowserTitle(this.blogDetails.title);
      /*if (response.status.code === 200) {
          this.blogDetails = response.blog;
      } else {
          this.toaster.show(response.status.message, true);
      }*/
    });
  }

}
