<!-- Add to cart modal popup start-->
<ng-template #cartModal let-modal>
  <div class="modal-body">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="modal-bg">
            <button (click)="modal.dismiss('Cross click')" class="close" id="close-cart-modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="media">
              <a [routerLink]="['/gifts/product/left/sidebar/', product.title.replace(' ', '-')]">
                <img appLazyLoad [alt]="product.images[0].alt" [src]="product.images[0].src" class="img-fluid pro-img">
              </a>
              <div class="media-body align-self-center text-center">
                <a [routerLink]="['/gifts/product/left/sidebar/', product.title.replace(' ', '-')]">
                  <h6>
                    <i class="fa fa-check"></i>Item
                    <span>{{ product.title | titlecase }}</span>
                    <span> successfully added to your Cart</span>
                  </h6>
                </a>
                <div class="buttons">
                  <a [routerLink]="['/gifts/cart']" class="view-cart btn btn-solid">Your cart</a>
                  <a [routerLink]="['/gifts/checkout']" class="checkout btn btn-solid">Check out</a>
                  <a [routerLink]="['/gifts/collection/left/sidebar']" class="continue btn btn-solid">Continue
                    shopping</a>
                </div>

                <div class="upsell_payment">
                  <img appLazyLoad alt="" class="img-fluid" src="assets/images/payment_cart.png">
                </div>
              </div>
            </div>

            <div class="product-section">
              <div class="col-12 product-upsell text-center">
                <h4>Customers who bought this item also.</h4>
              </div>
              <div class="row" id="upsell_product">
                <ng-container *ngFor="let product of products | slice:0:4">
                  <div class="product-box col-sm-3 col-6">
                    <div class="img-wrapper">
                      <div class="front">
                        <a [routerLink]="['/gifts/product/left/sidebar/', product.title.replace(' ', '-')]">
                          <img appLazyLoad [src]="product.images[0].src" alt="" class="img-fluid mb-1">
                        </a>
                      </div>
                      <div class="product-detail">
                        <h6 class="mt-0">
                          <a
                            [routerLink]="['/gifts/product/left/sidebar/', product.title.replace(' ', '-')]"><span>{{ product.title | titlecase }}</span></a>
                        </h6>
                        <h4><span>{{ product.price | currency: 'INR' : 'symbol' : '1.2'}}</span></h4>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Add to cart modal popup end-->
