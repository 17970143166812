import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit, AfterViewInit {

    @ViewChild('confirmationModal') confirmationModal: TemplateRef<any>;
    @Output('delete') delete: EventEmitter<any> = new EventEmitter<any>();

    constructor(public modal: NgbModal) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.modal.open(this.confirmationModal).result.then((result) => {
        }, (reason) => {
            this.delete.emit(reason);
        });
    }

}
