<ng-template #confirmationModal class="theme-modal" let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button (click)="modal.dismiss(false)" aria-label="Close" class="close"
                                    type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="confirm-modal-content">
                                <h3>Are you Sure?</h3>
                                <span>Do you want to delete this?</span>
                                <div class="confirm-buttons">
                                    <button (click)="modal.dismiss(false)" class="btn btn-sm btn-solid">No</button>
                                    <button (click)="modal.dismiss(true)" class="btn btn-sm btn-solid">Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
