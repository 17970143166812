<app-breadcrumb [title]="'Blog Details'" [breadcrumb]="'Blog Details'"></app-breadcrumb>
<!--section start-->
<section class="blog-detail-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 blog-detail">
              <h3>{{blogDetails?.title}}</h3>
              <span  *ngIf="blogDetails?.subDescription" [innerHTML]="blogDetails.subDescription"></span>
              <ul class="post-social">
                  <li *ngIf="blogDetails?.createdOn">{{blogDetails.createdOn | date: 'fullDate'}}</li>
                  <li *ngIf="blogDetails?.createdByName">Posted By : {{blogDetails.createdByName}}</li>
                </ul>
              <img appLazyLoad *ngIf="blogDetails?.imageUrl"   class="img-fluid" [alt]="blogDetails?.imageUrl"
              [src]="blogDetails?.imageUrl"/>

            </div>
        </div>
    </div>
</section>
<!--Section ends-->
