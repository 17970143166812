export class Url {
    public static serverUrl = '';
    public static API = {
        authentication: {
            login: '/user/login',
            register: '/user/sign-up',
            editProfile: '/user/edit-profile',
            getUserDetails: '/get_user_details/'
        },
        home: {
            getHomePageData: '/home-page/get',
              getToolbarData: '/sub-category/getAllCategoriesAndSubCategories'
        },
        shop: {
            getAllProducts: '/product/get/allProducts',
            getProductsByFilter: '/product/get/filter/all-Products',
            getProductById: '/product/get/',
            getSidebarProducts: '/product/getSideBar',
            getOrdersList: '/payment/get_orders/',
            getRelatedProducts: '/product/getRelated',
            getOrderSummary: '/payment/get/product_summary/'
        },
        newsletter: {
            subscribe: '/newsletter/subscribe'
        },
        cart: {
            add: '/cartItem/add',
            get: '/cartItem/getByUserId/',
            delete: '/cartItem/delete/'
        },
        wishlist: {
            add: '/wishlistItem/add',
            get: '/wishlistItem/getByUserId/',
            delete: '/wishlistItem/delete/'
        },
        addresses: {
            get: '/address/getByUserId/',
            add: '/address/add',
            edit: '/address/edit',
            delete: '/address/delete/'
        },
        payment: {
            getOrderId: '/payment/getOrderId',
            updateStatus: '/payment/updatePaymentStatus'
        },
        feedback: {
            add: '/feedback/add'
        },
        blogs: {
            getAll: '/blog/getAllHomePage',
            getById: '/blog/getById/'
        },
        enquiry: {
            add: '/bulkEnquiry/add'
        }
    };
}
