import { Component, HostListener, Input, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;
  public userLoggedIn: boolean = false;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.getObserver()
      .subscribe((result) => {
        if (Object.keys(result).length > 0) {
          this.userLoggedIn = true;
        } else {
          this.userLoggedIn = false;
        }
        // this.userLoggedIn = !!result;
      });
  }

  // @HostListener Decorator
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.stick = number >= 150 && window.innerWidth > 400;
  }

  logout() {
    this.userService.logout();
  }

}
