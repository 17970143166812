import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  NgModule
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { SharedModule } from './shared/shared.module';
import {
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebServiceModule } from './http/web-service.module';
import { CookieService } from 'ngx-cookie-service';
import { AppUtilService } from './app-util.service';
import { environment } from '../environments/environment';
import 'hammerjs';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function ConfigLoader(configService: any): any {
  return () => configService.load(environment.serverUrlConfig);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    WebServiceModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  providers: [CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [AppUtilService],
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
