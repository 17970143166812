<div class="navbar">
    <a (click)="leftMenuToggle()" href="javascript:void(0)">
        <div class="bar-style"><i aria-hidden="true" class="fa fa-bars sidebar-bar"></i></div>
    </a>
    <div [class.openSide]="navServices?.leftMenuToggle" class="sidenav" id="mySidenav">
        <a (click)="leftMenuToggle()" (mouseover)="onHover(false)" class="sidebar-overlay"
           href="javascript:void(0)"></a>
        <nav id="unset">
            <div (click)="leftMenuToggle()">
                <div class="sidebar-back text-left"><i aria-hidden="true" class="fa fa-angle-left pr-2"></i> Back
                </div>
            </div>
            <ul class="sidebar-menu" id="sub-menu">
                <li (mouseover)="onHover(menuItem.subCategories && menuItem.subCategories.length > 0)"
                    *ngFor="let menuItem of menuItems">
                    <!-- Link -->
                    <a (click)="toggleNavActive(menuItem)"
                       >
                        {{menuItem.displayName}}
                        <span *ngIf="menuItem.subCategories && menuItem.subCategories.length > 0"
                              class="sub-arrow"></span>
                    </a>
                    <!-- 2nd Level Menu -->
                    <ul *ngIf="menuItem.subCategories && menuItem.subCategories.length > 0"
                        [class.opensub1]="menuItem.active"
                        [class]="menuItem.megaMenu ? 'mega-menu clothing-menu' : ''">

                        <!-- Simple Menu Start-->
                        <ng-container *ngIf="!menuItem.megaMenu">
                            <li *ngFor="let childrenItem of menuItem.subCategories">
                                <!-- Link -->
                                <a (click)="leftMenuToggle()"
                                   [queryParams]="{category: childrenItem.categoryId, subCategory: childrenItem.id}"
                                   [routerLink]="['/gifts/' + menuItem.name + '/' + childrenItem?.name]" class="has-submenu">
                                    {{childrenItem.displayName}}
                                </a>
                            </li>
                        </ng-container>
                        <!-- Simple Menu End-->

                        <!-- Mega Menu Start-->
                        <ng-container *ngIf="menuItem.megaMenu">
                            <li>
                                <div class="row m-0">
                                    <div *ngFor="let childrenItem of menuItem.children" class="col-xl-4">
                                        <div class="link-section">
                                            <h5>{{childrenItem.title}}</h5>
                                            <ul>
                                                <li *ngFor="let childrenSubItem of childrenItem.children">
                                                    <!-- Link -->
                                                    <a *ngIf="childrenSubItem.type === 'link'"
                                                       [routerLink]="childrenSubItem.path">
                                                        {{childrenSubItem.title}}
                                                    </a>
                                                    <!-- External Link -->
                                                    <a *ngIf="childrenSubItem.type === 'extLink'"
                                                       href="{{ childrenSubItem.path }}">
                                                        {{childrenSubItem.title}}
                                                    </a>
                                                    <!-- External Tab Link -->
                                                    <a *ngIf="childrenSubItem.type === 'extTabLink'"
                                                       href="{{ childrenSubItem.path }}"
                                                       target="_blank">
                                                        {{childrenSubItem.title}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <a [routerLink]="['/home/one']" class="mega-menu-banner"><img appLazyLoad alt=""
                                                                                                      class="img-fluid"
                                                                                                      src=""></a>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <!-- Mega Menu End-->
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</div>

