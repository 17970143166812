import {
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Url } from '../../../http/url-constants';
import { WebService } from '../../../http/web-service.service';
import { ToasterService } from '../../services/toaster.service';
import { ProductService } from '../../services/product.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-footer-one',
    templateUrl: './footer-one.component.html',
    styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit, OnDestroy {

    @Input() class: string = 'footer-light';
    @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
    @Input() secureImage: string = 'assets/images/secure.webp';
    @Input() newsletter: boolean = true; // Default True
    public emailId: string;

    public today: number = Date.now();
    public categories: any[] = [];
    public categoriesSubscription: Subscription;

    constructor(private webService: WebService, private toasterService: ToasterService, private productService: ProductService) {
    }

    ngOnInit(): void {
        this.categoriesSubscription = this.productService.getCategories().subscribe((response) => {
            this.categories = response;
        });
    }

    subscribe() {
        this.webService.post(Url.API.newsletter.subscribe, {email: this.emailId}).subscribe((response) => {
            if (response.status.code === 200) {
                this.toasterService.show('Successfully Subscribed');
                this.emailId = undefined;
            } else {
                this.toasterService.show(response.status.message, true);
            }
        });
    }

    ngOnDestroy() {
        if (this.categoriesSubscription) {
            this.categoriesSubscription.unsubscribe();
        }
    }

}
