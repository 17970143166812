<div *ngIf="!loader">
    <div class="img-wrapper">
        <div class="lable-block">
            <span *ngIf="product.newArrival" class="lable3">new</span>
            <span *ngIf="product.festiveOffer" class="lable4">on sale</span>
        </div>
        <div class="front">
            <a  [routerLink]="['/gifts/products/' + product?.metaPath]"
                >
              <img appLazyLoad [src]="ImageSrc ? ImageSrc : product?.displayImage"
                     [alt]="product?.tags[0]"
                     class="img-fluid lazy-loading"
                     />
            </a>
        </div>
        <div *ngIf="onHowerChangeImage" class="back">
            <a  [routerLink]="['/gifts/products/' + product?.metaPath]"
                >
              <img appLazyLoad [src]="ImageSrc ? ImageSrc : product?.displayImage" [alt]="product?.tags[0]"
                     class="img-fluid lazy-loading">
            </a>
        </div>
        <ul *ngIf="thumbnail && [product?.productImages].length > 1" class="product-thumb-list">
            <li *ngFor="let image of [product?.productImages]; let index = index;" [class.active]="ImageSrc == image"
                class="grid_thumb_img">
                <a *ngIf="!fromOwnBox" (mouseover)="ChangeVariantsImage(image[index])"
                   [routerLink]="['/gifts/products/' + product.metaPath]"
                   >
                  <img appLazyLoad [alt]="product?.altTags[index]" [src]="image[index]">
                </a>
              <img appLazyLoad [alt]="product?.altTags[index]" [src]="image[index]" *ngIf="fromOwnBox">
            </li>
        </ul>
        <div class="cart-info cart-wrap" *ngIf="!fromOwnBox">
            <a (click)="addToCart(product)" *ngIf="cartModal" href="javascript:void(0)"
               title="Add to cart">
                <i class="ti-shopping-cart"></i>
            </a>
            <a (click)="addToCart(product)" *ngIf="!cartModal && !fromOwnBox" href="javascript:void(0)"
               title="Add to cart">
                <i class="ti-shopping-cart"></i>
            </a>
            <a (click)="addToWishlist(product)" href="javascript:void(0)" *ngIf="!fromOwnBox" title="Add to Wishlist">
                <i aria-hidden="true" class="ti-heart"></i>
            </a>
        </div>
    </div>
    <div class="product-detail">
        <div>
            <bar-rating [rate]="product.ratings" [readOnly]="true" [hidden]="product.ratings == null"></bar-rating>
            <a [routerLink]="['/gifts/products/' + product.metaPath]"
               >
                <h6 class="mt-2">{{ product?.productTitle | titlecase }}</h6>
            </a>
            <p [innerHTML]="product?.productDescription"></p>
            <h4>
                {{product?.sellingPrice | currency: 'INR' : 'symbol' : '1.2'}}
                <del *ngIf="product?.discountPercentage"><span
                        class="money">{{ product?.totalPrice | currency:'INR':'symbol' : '1.2'}}</span></del>
            </h4>
            <ul *ngIf="Color(product?.variants).length" class="color-variant">
                <li (click)="ChangeVariants(color, product)" *ngFor="let color of Color(product?.variants)"
                    [class]="color"
                    [ngStyle]="{'background-color': color}">
                </li>
            </ul>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader && !fromOwnBox" ></app-skeleton-product-box>
<app-quick-view #quickView [currency]="currency" [product]="product" *ngIf="!fromOwnBox"></app-quick-view>
<app-cart-modal #cartModal *ngIf="cartModal && !fromOwnBox" [currency]="currency" [product]="product"></app-cart-modal>

