<!--footer section -->
<section>
    <footer [class]="class">
        <div *ngIf="newsletter" class="light-layout">
            <div class="container">
                <section class="small-section border-section border-top-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="subscribe">
                                <div>
                                    <h4>KNOW IT ALL FIRST!</h4>
                                    <p>Never Miss Anything From Giftoholics By Signing Up To Our Newsletter. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <form #subscriptionForm="ngForm" (ngSubmit)="subscriptionForm.valid && subscribe()"
                                  class="form-inline subscribe-form" target="_blank">
                                <div class="form-group mx-sm-3">
                                    <div class="column">
                                        <input #email="ngModel" [(ngModel)]="emailId" class="form-control"
                                               id="mce-EMAIL"
                                               name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$"
                                               placeholder="Enter your email"
                                               required type="email">
                                        <div *ngIf="email.errors && (email.touched || email.dirty || subscriptionForm.submitted)">
                  <span [hidden]="!email.errors.pattern" class="text-danger">
                        Please enter a valid Email Address.
                      </span>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-solid" type="submit">subscribe</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <section class="section-b-space light-layout">
            <div class="container">
                <div class="row footer-theme partition-f">
                    <div class="col-lg-4 col-md-6">
                        <div class="footer-title footer-mobile-title">
                            <h4>about</h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo">
                              <img appLazyLoad [src]="themeLogo" alt="logo" width="75px">
                            </div>
                            <p>An online gift shop for unique and creative gifts for guys and girls. At Giftoholics.com,
                                explore the most unique gifts for any kind of person, or occasion.</p>
                            <div class="footer-social">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/giftoholicsofficial" target="_blank">
                                            <i aria-hidden="true" class="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://instagram.com/giftoholicsofficial?igshid=15e437wvzik76"
                                           target="_blank">
                                            <i aria-hidden="true" class="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCMrWFy0bXxZ3rJ6JxIu8LdQ"
                                           target="_blank">
                                            <i aria-hidden="true" class="fa fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col offset-xl-1">
                        <div class="sub-title">
                            <div class="footer-title">
                                <h4>Categories</h4>
                            </div>
                            <div class="footer-contant">
                                <ul>
                                    <li *ngFor="let category of categories | slice: 0 : 5">
                                        <a [routerLink]="['/gifts/' + category.name]"
                                           href="javascript:void(0)">{{category.displayName}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="sub-title">
                            <div class="footer-title">
                                <h4>Quick Links</h4>
                            </div>
                            <div class="footer-contant">
                                <ul>
                                    <li><a [routerLink]="['/about-us']" href="javascript:void(0)">About Us</a></li>
                                    <li>
                                        <a [routerLink]="['/privacy-policy']" href="javascript:void(0)">Privacy
                                            Policy</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/terms-conditions']" href="javascript:void(0)">Terms &
                                            Conditions</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/gifts/enquiry']" href="javascript:void(0)">Enquiry</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="sub-title">
                            <div class="footer-title">
                                <h4>store information</h4>
                            </div>
                            <div class="footer-contant">
                                <ul class="contact-list">
                                    <li><i class="fa fa-map-marker"></i>
                                        <a [href]="'https://goo.gl/maps/Xz14nsgFKZKtkHNk7'" target="_blank" class="cursor-pointer">C-112 Agam Vivana, Vesu,
                                        Surat Gujarat 395007
                                          India.</a>
                                    </li>
                                    <li>
                                        <i class="fa fa-phone"></i>Call Us:
                                        <a class="text-theme-primary" href="tel: +91 89052 53279">+91 89052 53279</a>
                                    </li>
                                    <li><i class="fa fa-envelope-o"></i>Email Us:
                                        <a href="mailto:support@giftoholics.com">support@giftoholics.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="sub-footer">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-12">
                        <div class="footer-end">
                            <p><i aria-hidden="true" class="fa fa-copyright"></i> {{ today | date:'y'}} Giftoholics
                                Made with ♥ by Digiflex Developers <img appLazyLoad [src]="secureImage" alt="logo" width="75px"></p>

                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12">
                        <div class="payment-card-bottom">
                            <ul>
                                <li>
                                    <a><img appLazyLoad alt="" src="assets/images/icon/visa.png"></a>
                                </li>
                                <li>
                                    <a><img appLazyLoad alt="" src="assets/images/icon/mastercard.png"></a>
                                </li>
                                <li>
                                    <a><img appLazyLoad alt="" src="assets/images/icon/paypal.png"></a>
                                </li>
                                <li>
                                    <a><img appLazyLoad alt="" src="assets/images/icon/american-express.png"></a>
                                </li>
                                <!-- <li>
                                    <a><img alt="" src="assets/images/icon/discover.png"></a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</section>
<!--footer section end -->
