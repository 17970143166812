import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

    public menuItems: any[];
    public categoriesSubscription: Subscription;

    constructor(private router: Router, public navServices: NavService, private productService: ProductService,
                public titleService: Title) {
        this.router.events.subscribe((event) => {
            this.navServices.mainMenuToggle = false;
        });
    }

    ngOnInit(): void {
        this.getAllData();
    }

    mainMenuToggle(): void {
        this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    }

    toggletNavActive(item) {
        item.active = !item.active;
    }

    getAllData() {
        this.categoriesSubscription = this.productService.getCategories().subscribe((response) => {
          this.menuItems = response;
        });
    }

    ngOnDestroy() {
        if (this.categoriesSubscription) {
            this.categoriesSubscription.unsubscribe();
        }
    }

}
