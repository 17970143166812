<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img appLazyLoad alt="" class="img-fluid" src="assets/images/icon/search.png">
        <i class="ti-search"></i>
        <!--<input class="form-control" id="searchss"
               (keyup.enter)="onSearchProducts()"
               [(ngModel)]="searchProductText"
               name="seacrhProduts"
               placeholder="Search a Product on enter" type="text">
        <i class="ti-search"></i>-->
      </div>
      <div [ngStyle]="{'display': search ? 'block' : 'none' }" class="search-overlay" id="search-overlay">
        <div>
          <span (click)="searchToggle()" class="closebtn" title="Close Overlay">×</span>
          <div class="overlay-content">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <form>
                    <div class="form-group">
                      <input class="form-control" id="search"
                             (keyup.enter)="onSearchProducts()"
                             [(ngModel)]="searchProductText"
                             name="seacrhProduts"
                             placeholder="Search a Product on enter" type="text">
                    </div>
                    <!-- <button class="btn btn-primary" type="submit"><i class="fa fa-search"></i>
                     </button>-->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/gifts/cart']">
          <img appLazyLoad alt="" class="img-fluid" src="assets/images/icon/cart.png">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls" *ngIf="products.length > 0">{{products.length}}</span>
      <ul *ngIf="!products.length" class="show-div shopping-cart">
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul *ngIf="products.length" class="show-div shopping-cart">
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/gifts/products/' + product.metaPath]"
            >
              <img appLazyLoad [alt]="'Image Not Found'" [src]="product?.displayImage" class="mr-3"
                   onerror="this.src='assets/images/product/placeholder.jpg';">
            </a>
            <div class="media-body">
              <a [routerLink]="['/gifts/products/' + product.metaPath]"
              >
                <h4>{{ product?.productTitle | titlecase}}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.minQuantity }} x
                  {{ (product?.sellingPrice + product?.shippingPrice) | currency: 'INR' :'symbol' : '1.2' }}
                </span>
              </h4>
            </div>
          </div>
          <div (click)="removeItem(product)" class="close-circle">
            <a href="javascript:void(0)"><i aria-hidden="true" class="fa fa-times"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ subTotal | currency: 'INR' :'symbol' : '1.2' }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/gifts/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/gifts/check-out']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>
