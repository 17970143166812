import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    ReplaySubject
} from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '../classes/product';
import { WebService } from '../../http/web-service.service';
import { Url } from '../../http/url-constants';
import { CookieService } from 'ngx-cookie-service';

const state = {
    products: JSON.parse(localStorage.products || '[]'),
    wishlist: JSON.parse(localStorage.wishlistItems || '[]'),
    compare: JSON.parse(localStorage.compareItems || '[]'),
    cart: JSON.parse(localStorage.cartItems || '[]')
};

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    public Currency = {
        name: 'Dollar',
        currency: 'USD',
        price: 1
    }; // Default Currency
    public OpenCart = false;
    public Products;
    public cartItemsAdded = new BehaviorSubject(true);
    public Observable: ReplaySubject<any> = new ReplaySubject();
    public WishlistObservable: ReplaySubject<any> = new ReplaySubject();
    public CategoriesObservable: ReplaySubject<any> = new ReplaySubject();
    public searchObservable: BehaviorSubject<any> = new BehaviorSubject(null);
    public products: any[] = [];
    public categories: any[] = [];
    public services: any[] = [];

    constructor(private webService: WebService, private cookieService: CookieService) {
    }

    public get wishlistItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.wishlist);
            observer.complete();
        });
        return itemsStream as Observable<Product[]>;
    }

    public get compareItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.compare);
            observer.complete();
        });
        return itemsStream as Observable<Product[]>;
    }

    public get cartItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.cart);
            observer.complete();
        });
        return itemsStream as Observable<Product[]>;
    }

    getProducts(): Observable<any[]> {
        return this.Observable;
    }

    getWishlistProducts(): Observable<any[]> {
        return this.WishlistObservable;
    }

    getWishlistItems(): any {
        return localStorage.wishlistItems ? JSON.parse(localStorage.wishlistItems) : [];
    }

    public addToWishlist(product): any {
        const wishlistItem = state.wishlist.find(item => item.id === product.id);
        if (!wishlistItem) {
            state.wishlist.push({
                ...product
            });
        }
        localStorage.setItem('wishlistItems', JSON.stringify(state.wishlist));
        this.WishlistObservable.next(state.wishlist);
        return true;
    }

    public removeWishlistItem(product: Product): any {
        const index = state.wishlist.indexOf(product);
        state.wishlist.splice(index, 1);
        localStorage.setItem('wishlistItems', JSON.stringify(state.wishlist));
        this.WishlistObservable.next(state.wishlist);
        return true;
    }

    // Remove Cart items
    public removeAllWishlistItems(): any {
        state.wishlist = [];
        localStorage.removeItem('wishlistItems');
        this.WishlistObservable.next([]);
        return true;
    }

    public addToCompare(product): any {
        const compareItem = state.compare.find(item => item.id === product.id);
        if (!compareItem) {
            state.compare.push({
                ...product
            });
        }
        localStorage.setItem('compareItems', JSON.stringify(state.compare));
        return true;
    }

    public removeCompareItem(product: Product): any {
        const index = state.compare.indexOf(product);
        state.compare.splice(index, 1);
        localStorage.setItem('compareItems', JSON.stringify(state.compare));
        return true;
    }

    public addToCart(product): any {
        const cartItem = state.cart.find(item => item.id === product.id);
        const qty = product.quantity ? product.quantity : 1;
        const items = cartItem ? cartItem : product;
        const stock = this.calculateStockCounts(items, qty);
        if (!stock) {
            return false;
        }
        if (cartItem) {
            cartItem.quantity += qty;
        } else {
            state.cart.push({
                ...product,
                quantity: qty
            });
        }
        this.OpenCart = true; // If we use cart variation modal
        localStorage.setItem('cartItems', JSON.stringify(state.cart));
        this.Observable.next(state.cart);
        return true;
    }

    // Update Cart Quantity
    public updateCartQuantity(product: Product, quantity: number): Product | boolean {
        return state.cart.find((items, index) => {
            if (items.id === product.id) {
                const qty = state.cart[index].quantity + quantity;
                const stock = this.calculateStockCounts(state.cart[index], quantity);
                if (qty !== 0 && stock) {
                    state.cart[index].quantity = qty;
                }
                localStorage.setItem('cartItems', JSON.stringify(state.cart));
                return true;
            }
        });
    }

    // Calculate Stock Counts
    public calculateStockCounts(product, quantity) {
        const qty = product.quantity + quantity;
        const stock = product.stock;
        return !(stock < qty || stock == 0);

    }

    // Remove Cart items
    public removeCartItem(product: Product): any {
        const index = state.cart.indexOf(product);
        state.cart.splice(index, 1);
        localStorage.setItem('cartItems', JSON.stringify(state.cart));
        this.Observable.next(state.cart);
        return true;
    }

    // Remove Cart items
    public removeAllCartItems(): any {
        state.cart = [];
        localStorage.removeItem('cartItems');
        this.Observable.next([]);
        return true;
    }

    // Total amount
    public cartTotalAmount(): Observable<number> {
        return this.cartItems.pipe(map((product: Product[]) => {
            return product.reduce((prev, curr: Product) => {
                let price = curr.price;
                if (curr.discount) {
                    price = curr.price - (curr.price * curr.discount / 100);
                }
                return (prev + price * curr.quantity) * this.Currency.price;
            }, 0);
        }));
    }

    // Sorting Filter
    public sortProducts(products: Product[], payload: string): any {
        if (payload === 'ascending') {
            return products.sort((a, b) => {
                if (a.id < b.id) {
                    return -1;
                } else if (a.id > b.id) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'a-z') {
            return products.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'z-a') {
            return products.sort((a, b) => {
                if (a.title > b.title) {
                    return -1;
                } else if (a.title < b.title) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'low') {
            return products.sort((a, b) => {
                if (a.price < b.price) {
                    return -1;
                } else if (a.price > b.price) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'high') {
            return products.sort((a, b) => {
                if (a.price > b.price) {
                    return -1;
                } else if (a.price < b.price) {
                    return 1;
                }
                return 0;
            });
        }
    }

    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
      // calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        const paginateRange = 3;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number,
                endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems,
            currentPage,
            pageSize,
            totalPages,
            startPage,
            endPage,
            startIndex,
            endIndex,
            pages
        };
    }

    public setCategories() {
        this.webService.get(Url.API.home.getToolbarData).subscribe((response) => {
            if (response.status.code === 200) {
                this.categories = response.responseList;
                this.CategoriesObservable.next(this.categories);
            }
        });
    }

    public getCategories(): Observable<any> {
        return this.CategoriesObservable;
    }
    public searchProduct(): Observable<any>{
      return this.searchObservable;
    }

    public setServices(services: any[]) {
        this.services = services;
        this.cookieService.set('services', JSON.stringify(this.services));
    }

    public getServices() {
        return this.services;
    }

}
