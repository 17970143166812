import { NgModule } from '@angular/core';


import {
  RouterModule,
  Routes
} from '@angular/router';
import { BlogDetailsComponent } from './feature-modules/homepage/blog/blog-details/blog-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'home',
    loadChildren: () => import('./feature-modules/homepage/homepage.module').then(m => m.HomepageModule)
  },
  {
    path: 'gifts',
    loadChildren: () => import('./feature-modules/shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: '',
    loadChildren: () => import('./feature-modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '',
    loadChildren: () => import('./feature-modules/information-pages/information-pages.module').then(m => m.InformationPagesModule)
  },
  {
    path: 'blog-details',
    component: BlogDetailsComponent
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes ,  {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
