import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
    BehaviorSubject,
    Observable
} from 'rxjs';
import { ProductService } from './product.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public userData: any = {};
    public Observable: BehaviorSubject<any> = new BehaviorSubject(this.userData);

    constructor(private cookieService: CookieService, private productService: ProductService, private router: Router) {
    }

    setUserData(data: any) {
        this.userData = data;
        this.cookieService.set('user', JSON.stringify(this.userData));
        this.Observable.next(this.userData);
    }

    getUserData() {
        return this.userData;
    }

    getUserProperty(key: string) {
        return this.userData[key];
    }

    getObserver(): Observable<any> {
        return this.Observable;
    }

    logout() {
        this.cookieService.delete('user');
        this.productService.removeAllCartItems();
        this.productService.removeAllWishlistItems();
        this.userData = {};
        this.setUserData({});
        this.Observable.next(this.userData);
        this.router.navigateByUrl('/home');
      }
    }
