/**
 * Author-mayur.
 */

import {NgModule,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebServiceConfig} from './webserviceconfig';
import {WebService} from './web-service.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [WebService]
})
export class WebServiceModule {

  constructor() {
  };

  static forRoot(config: WebServiceConfig) {
    return {
      ngModule: WebServiceModule,
      providers: [
        {provide: WebServiceConfig, useValue: config}
      ]
    };
  }
}
