import {
    Injectable,
    Optional
} from '@angular/core';
import { WebServiceConfig } from './webserviceconfig';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams
} from '@angular/common/http';
import {
    Observable,
    Subject,
    timer
} from 'rxjs';
import { Url } from './url-constants';

@Injectable()
export class WebService {

    public unAuthorizedError: boolean = false;
    public fileUploadProgress: Subject<any> = new Subject<any>();

    constructor(@Optional() private config: WebServiceConfig,
                private http: HttpClient) {
    }

    private static getRequestOptions(params?: any, body?: any, contentType?: string): any {
        const httpOptions: any = {};

        /*set header section*/
        const httpHeader = {};
        httpHeader['Content-Type'] = 'application/json';

        /*set content type for multipart*/
        /*if (contentType) {
          httpHeader['Content-type'] = contentType;
        }*/
        httpOptions['headers'] = new HttpHeaders(httpHeader);

        /*Set param section*/
        if (params) {
            httpOptions['params'] = new HttpParams({fromObject: params});
        }

        /*Set param section*/
        if (body) {
            httpOptions['body'] = body;
        }
        return httpOptions;
    }

    private static getFullUrl(url: string, backendUrl?: string): string {
        if (backendUrl) {
            return backendUrl + url;
        }
        return Url.serverUrl + url;
    }

    public get(endPoint: string, backendUrl?: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {
                this.http.get(WebService.getFullUrl(endPoint, backendUrl), WebService.getRequestOptions()).subscribe(response => resolve.next(response), (httpError) => {
                    count++;
                    resolve.error(httpError);
                });
            };
            takeCallback();
        });
    }

    public getWithParams(endPoint: string, params: any, backendUrl?: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {

                this.http.get(WebService.getFullUrl(endPoint, backendUrl), WebService.getRequestOptions(params)).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    public getFromUrl(endPoint: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {

                this.http.get(endPoint, WebService.getRequestOptions()).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    public getFromUrlWithOutHeader(endPoint: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {

                this.http.get(endPoint).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    public post(endPoint: string, data: any, backendUrl?: string): Observable<any> {
        return new Observable((resolve) => {
            const takeCallback = (): void => {

                this.http.post(WebService.getFullUrl(endPoint, backendUrl), data, WebService.getRequestOptions()).subscribe(response => resolve.next(response), (httpError) => {
                    resolve.error(httpError);
                });

            };

            takeCallback();
        });
    }

    public postFromUrl(endPoint: string, data: any): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {

                this.http.post(endPoint, data, WebService.getRequestOptions()).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    public put(endPoint: string, data?: any, params?: any, backendUrl?: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {

                this.http.put(WebService.getFullUrl(endPoint, backendUrl), data, WebService.getRequestOptions(params)).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    public delete(endPoint: string, data?: any, backendUrl?: string, param?: any): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {
                this.http.delete(WebService.getFullUrl(endPoint, backendUrl), WebService.getRequestOptions(param, data)).subscribe(response => resolve.next(response), (httpError) => {
                    count++;
                    resolve.error(httpError);
                });

            };

            takeCallback();
        });
    }

    multipartFileUpload(endPoint: string, formData: FormData, data?: any, backendUrl?: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {
                let xhr: XMLHttpRequest;

                const requestOptions = WebService.getRequestOptions();
                return Observable.create((observer: any) => {
                    xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                observer.next(JSON.parse(xhr.response));
                                observer.complete();
                            } else {
                                observer.error(xhr.response);
                            }
                        }
                    };
                    xhr.upload.onprogress = (event) => {
                        const progress = Math.round(event.loaded / event.total * 100);
                        this.fileUploadProgress.next(progress);
                    };
                    xhr.open('POST', WebService.getFullUrl(endPoint, backendUrl), true);
                    //    xhr.setRequestHeader('Authorization', requestOptions.headers.get('Authorization'));
                    //  xhr.setRequestHeader('language', requestOptions.headers.get('language'));
                    xhr.send(formData);
                }).subscribe(response => resolve.next(response), (httpError) => {
                    count++;
                });

            };

            takeCallback();
        });
    }

    multipartFileUploadForPUT(endPoint: string, formData: FormData, data?: any, backendUrl?: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {
                let xhr: XMLHttpRequest;

                const requestOptions = WebService.getRequestOptions();
                return Observable.create((observer: any) => {
                    xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                observer.next(JSON.parse(xhr.response));
                                observer.complete();
                            } else {
                                observer.error(xhr.response);
                            }
                        }
                    };
                    xhr.upload.onprogress = (event) => {
                        const progress = Math.round(event.loaded / event.total * 100);
                        this.fileUploadProgress.next(progress);
                    };
                    xhr.open('PUT', WebService.getFullUrl(endPoint, backendUrl), true);
                    xhr.setRequestHeader('Authorization', requestOptions.headers.get('Authorization'));
                    xhr.setRequestHeader('language', requestOptions.headers.get('language'));
                    xhr.send(formData);
                }).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    public downloadCertificate(endPoint: string, contentType: string, backendUrl?: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {
                const requestOptions = WebService.getRequestOptions();
                requestOptions.responseType = 'blob';
                this.http.get(WebService.getFullUrl(endPoint, backendUrl), requestOptions).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    public downloadCertificatePost(endPoint: string, data: any, contentType: string, backendUrl?: string): Observable<any> {
        let count = 0;
        return new Observable((resolve) => {
            const takeCallback = (): void => {
                const requestOptions = WebService.getRequestOptions();
                requestOptions.responseType = 'blob';
                this.http.post(WebService.getFullUrl(endPoint, backendUrl), data, requestOptions).subscribe(response => resolve.next(response), (httpError) => {
                    count++;

                });

            };

            takeCallback();
        });
    }

    private getTokenDetails() {
        //return this.oAuthService.getAccessToken();
    }

    private checkToken(): Promise<any> {
        return new Promise((resolve) => {
            let count = 0;
            const source = timer(1000, 1000);
            const subscription = source.subscribe(time => {
                count++;
                if (count > 5) {
                    subscription.unsubscribe();
                    resolve(true);
                }
            });
        });
    }

    private handleError(error: HttpErrorResponse | any) {

    }
}

