import { NgModule } from '@angular/core';
import {
    CommonModule,
    DatePipe
} from '@angular/common';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { LayoutBoxComponent } from './components/layout-box/layout-box.component';
import { RouterModule } from '@angular/router';
import { HeaderOneComponent } from './header/header-one/header-one.component';
import { SettingsComponent } from './components/settings/settings.component';
import { MenuComponent } from './components/menu/menu.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ProductBoxOneComponent } from './components/product-box-one/product-box-one.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ProductBoxVerticalSliderComponent } from './components/product-box-vertical-slider/product-box-vertical-slider.component';
import { ServicesComponent } from './components/services/services.component';
import { SocialComponent } from './components/social/social.component';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import {
    IConfig,
    NgxMaskModule
} from 'ngx-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { ConfirmationModalComponent } from './components/modal/confirmation-modal/confirmation-modal.component';
import { Title } from '@angular/platform-browser';
import { LazyLoadDirective } from './directives/lazy-load.directive';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [TapToTopComponent, LayoutBoxComponent, HeaderOneComponent, SettingsComponent, MenuComponent,
        LeftMenuComponent, FooterOneComponent, ProductBoxOneComponent, CartModalComponent, QuickViewComponent, NewsletterComponent,
        BreadcrumbComponent, CategoriesComponent, ProductBoxVerticalSliderComponent, ServicesComponent,
        SocialComponent, ConfirmationModalComponent, LazyLoadDirective],
    imports: [
        CommonModule, RouterModule, TranslateModule, CarouselModule, BarRatingModule,
        FormsModule, ToastrModule.forRoot(), NgxMaskModule.forRoot(maskConfig), ClipboardModule
    ],
    exports: [TapToTopComponent, LayoutBoxComponent, HeaderOneComponent, SettingsComponent, MenuComponent, LeftMenuComponent,
        FooterOneComponent, CarouselModule, ProductBoxOneComponent, CommonModule, NewsletterComponent, BreadcrumbComponent,
        CategoriesComponent, ProductBoxVerticalSliderComponent, ServicesComponent, SocialComponent, FormsModule, NgxMaskModule,
        ConfirmationModalComponent, LazyLoadDirective],
    providers: [DatePipe, Title]
})
export class SharedModule {
}
